import { cilActionRedo } from '@coreui/icons'
import { createStore, compose, applyMiddleware } from 'redux'
import {
  TOGGLE_IS_LOGGED,
  CONNECT_SUPER_ADMIN,
  SAVE_PARTICULIERS,
  SAVE_ENTREPRISES,
  CHANGE_INPUT_VALUE,
  CHANGE_DATE_DEBUT,
  CHANGE_DATE_FIN,
  CHANGE_FILTERS_GRAPH,
  SAVE_FACTURES,
  SAVE_FACTURES_PREV,
  SAVE_SECTEURS,
  SAVE_FORMATIONS,
  REPLACE_FACTURES,
  SAVE_FORMATIONS_EN_COURS,
  SAVE_FORMATIONS_ABANDON,
  SAVE_FORMATIONS_FINIT,
  SAVE_FORMATIONS_TOTAL,
  SAVE_FORMATIONS_PREVISION,
  SAVE_FORMATIONS_ENCAISSE,
} from './actions/actions'
import { persistStore, persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

const reformatDate = (date) => {
  // Séparation des différentes parties de la date
  const [day, month, year] = date.split('/')
  // Retourne la date reformatée
  return `${year}-${month}-${day}`
}

const initialState = {
  sidebarShow: true,
  isLogged: false,
  superAdmin: {},
  particuliers: {},
  entreprises: {},
  secteurData: [],
  formationData: [],
  periode: {
    debut: '2022-09-01',
    fin: reformatDate(new Date().toLocaleDateString('fr-FR')),
  },
  secteur: false,
  secteurFilterName: false,
  nomFormation: false,
  formationFilterName: false,
  statut: false,
  nomClient: false,
  nomClientInput: '',
  clientFilterName: false,
  ville: false,
  opco: false,
  opcoFilterName: false,
  filterFormation: {
    demande: true,
    valide: true,
  },
  filterCA: {
    encours: true,
    abandon: true,
    finit: true,
    total: true,
    prevision: true,
    encaisse: true,
  },
  factures: 'loading',
  facturesPrev: 'loading',
  formationDataEnCours: [],
  formationDataAbandon: [],
  formationDataFinit: [],
  formationDataTotal: [],
  formationDataPrevision: [],
  formationDataEncaisse: [],
}

const changeState = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'set':
      return { ...state }
    case TOGGLE_IS_LOGGED:
      return {
        ...state,
        isLogged: action.bool,
      }
    case CONNECT_SUPER_ADMIN:
      return {
        ...state,
        isLogged: action.bool,
        superAdmin: action.data,
      }
    case SAVE_PARTICULIERS:
      return {
        ...state,
        particuliers: action.data,
      }
    case SAVE_ENTREPRISES:
      return {
        ...state,
        entreprises: action.data,
      }
    case CHANGE_INPUT_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      }
    case CHANGE_DATE_DEBUT:
      return {
        ...state,
        periode: {
          debut: action.data,
          fin: state.periode.fin,
        },
      }
    case CHANGE_DATE_FIN:
      return {
        ...state,
        periode: {
          debut: state.periode.debut,
          fin: action.data,
        },
      }
    case CHANGE_FILTERS_GRAPH:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          [action.key]: action.value,
        },
      }
    case SAVE_FACTURES:
      return {
        ...state,
        factures: action.data,
      }
    case SAVE_FACTURES_PREV:
      return {
        ...state,
        facturesPrev: action.data,
      }
    case SAVE_SECTEURS:
      return {
        ...state,
        secteurData: action.data,
      }
    case SAVE_FORMATIONS:
      return {
        ...state,
        formationData: action.data,
      }
    case REPLACE_FACTURES:
      return {
        ...state,
        factures: {
          ...state.factures,
          data: action.data,
        },
      }
    case SAVE_FORMATIONS_EN_COURS:
      return {
        ...state,
        formationDataEnCours: action.data,
      }
    case SAVE_FORMATIONS_ABANDON:
      return {
        ...state,
        formationDataAbandon: action.data,
      }
    case SAVE_FORMATIONS_FINIT:
      return {
        ...state,
        formationDataFinit: action.data,
      }
    case SAVE_FORMATIONS_TOTAL:
      return {
        ...state,
        formationDataTotal: action.data,
      }
    case SAVE_FORMATIONS_PREVISION:
      return {
        ...state,
        formationDataPrevision: action.data,
      }
    case SAVE_FORMATIONS_ENCAISSE:
      return {
        ...state,
        formationDataEncaisse: action.data,
      }
    default:
      return state
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistConfig = {
  key: 'root',
  timeout: 5000,
  storage: sessionStorage,
  whitelist: ['isLogged', 'superAdmin', 'particuliers', 'entreprises'],
}

const persistedReducer = persistReducer(persistConfig, changeState)

const enhancers = composeEnhancers(applyMiddleware())

export const store = createStore(persistedReducer, enhancers)
export const persistedStore = persistStore(store)
