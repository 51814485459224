export const TOGGLE_IS_LOGGED = 'TOGGLE_IS_LOGGED'
export const CONNECT_SUPER_ADMIN = 'CONNECT_SUPER_ADMIN'
export const SAVE_PARTICULIERS = 'SAVE_PARTICULIERS'
export const SAVE_ENTREPRISES = 'SAVE_ENTREPRISES'
export const CHANGE_DATE_DEBUT = 'CHANGE_DATE_DEBUT'
export const CHANGE_DATE_FIN = 'CHANGE_DATE_FIN'
export const CHANGE_INPUT_VALUE = 'CHANGE_INPUT_VALUE'
export const CHANGE_FILTERS_GRAPH = 'CHANGE_FILTERS_GRAPH'
export const SAVE_FACTURES = 'SAVE_FACTURES'
export const SAVE_FACTURES_PREV = 'SAVE_FACTURES_PREV'
export const SAVE_SECTEURS = 'SAVE_SECTEURS'
export const SAVE_FORMATIONS = 'SAVE_FORMATIONS'
export const REPLACE_FACTURES = 'REPLACE_FACTURES'
export const SAVE_FORMATIONS_EN_COURS = 'SAVE_FORMATIONS_EN_COURS'
export const SAVE_FORMATIONS_ABANDON = 'SAVE_FORMATIONS_ABANDON'
export const SAVE_FORMATIONS_FINIT = 'SAVE_FORMATIONS_FINIT'
export const SAVE_FORMATIONS_TOTAL = 'SAVE_FORMATIONS_TOTAL'
export const SAVE_FORMATIONS_PREVISION = 'SAVE_FORMATIONS_PREVISION'
export const SAVE_FORMATIONS_ENCAISSE = 'SAVE_FORMATIONS_ENCAISSE'

export const toggleIsLogged = (bool) => ({
  type: TOGGLE_IS_LOGGED,
  bool,
})

export const connectSuperAdmin = (bool, data) => ({
  type: CONNECT_SUPER_ADMIN,
  bool,
  data,
})

export const saveParticuliers = (data) => ({
  type: SAVE_PARTICULIERS,
  data,
})

export const saveEntreprises = (data) => ({
  type: SAVE_ENTREPRISES,
  data,
})

export const changeDateDebut = (data) => ({
  type: CHANGE_DATE_DEBUT,
  data,
})

export const changeDateFin = (data) => ({
  type: CHANGE_DATE_FIN,
  data,
})

export const changeInputValue = (value, name) => ({
  type: CHANGE_INPUT_VALUE,
  value,
  name,
})

export const changeFiltersGraph = (name, key, value) => ({
  type: CHANGE_FILTERS_GRAPH,
  name,
  key,
  value,
})

export const saveFactures = (data) => ({
  type: SAVE_FACTURES,
  data,
})

export const saveFacturesPrev = (data) => ({
  type: SAVE_FACTURES_PREV,
  data,
})

export const saveSecteurs = (data) => ({
  type: SAVE_SECTEURS,
  data,
})

export const saveFormations = (data) => ({
  type: SAVE_FORMATIONS,
  data,
})

export const replaceFactures = (data) => ({
  type: REPLACE_FACTURES,
  data,
})

export const saveFormationsEnCours = (data) => ({
  type: SAVE_FORMATIONS_EN_COURS,
  data,
})

export const saveFormationsAbandon = (data) => ({
  type: SAVE_FORMATIONS_ABANDON,
  data,
})

export const saveFormationsFinit = (data) => ({
  type: SAVE_FORMATIONS_FINIT,
  data,
})

export const saveFormationsTotal = (data) => ({
  type: SAVE_FORMATIONS_TOTAL,
  data,
})

export const saveFormationsPrevision = (data) => ({
  type: SAVE_FORMATIONS_PREVISION,
  data,
})

export const saveFormationsEncaisse = (data) => ({
  type: SAVE_FORMATIONS_ENCAISSE,
  data,
})
